.touchevents .animate-grow-in {
  transform: scale(0.95);
  transition: transform 0.8s;
}
.touchevents .animate-grow-in.come-in {
  transform: scale(1);
}

.animate-grow-in.come-in {
  animation: grow-in 1s ease-in-out forwards;
}

.animate-grow-in.already-visible {
  animation: none;
}

@keyframes grow-in {
  0% {
    transform: scale(0.93);
  }
  70% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}