.touchevents .animate-zoom-in {
  opacity: 0;
  transition: opacity 0.05s;
}
.touchevents .animate-zoom-in.come-in {
  opacity: 1;
}

.animate-zoom-in.come-in {
  transform: scale(0.3);
  animation: come-in 1.2s ease forwards;
}

.animate-zoom-in.already-visible {
  transform: scale(1);
  animation: none;
}

@keyframes come-in {
  to {
    transform: scale(1);
  }
}