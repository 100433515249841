[id=floatbar] {
  left: 0;
  color: #fff;
  width: 100%;
  bottom: 0;
  padding: 5px 10px;
  z-index: 700;
  position: fixed;
  background: rgba(33, 33, 33, 0.7);
  text-align: center;
  transition: all 0.2s;
}
[id=floatbar][aria-hidden=true] {
  bottom: -80px;
  opacity: 0;
  z-index: -1;
}
@media screen and (min-width: 500px) {
  [id=floatbar] {
    padding: 10px;
  }
}