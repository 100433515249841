.touchevents .animate-slide-in {
  opacity: 0;
  transition: opacity 0.05s;
}
.touchevents .animate-slide-in.come-in {
  opacity: 1;
}

.animate-slide-in.come-in {
  transform: translateY(80px);
  animation: come-in 0.6s ease forwards;
}

.animate-slide-in.already-visible {
  transform: translateY(0);
  animation: none;
}

.animate-slide-in-left.come-in {
  animation: slide-in-left 1s ease-in-out forwards;
}

.animate-slide-in-right.come-in {
  animation: slide-in-right 1s ease-in-out forwards;
}

@keyframes slide-in-left {
  from {
    transform: translate3d(-30%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slide-in-right {
  from {
    transform: translate3d(50%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes come-in {
  to {
    transform: translateY(0);
  }
}