.touchevents .animate-fade-in {
  opacity: 0;
  transition: opacity 0.05s;
}
.touchevents .animate-fade-in.come-in {
  opacity: 1;
}

.animate-fade-in.come-in {
  animation: fade-in 1s ease-in-out forwards;
}

.animate-fade-in.already-visible {
  animation: none;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}